@import "~antd/dist/antd.css";

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;900&display=swap");

div {
  z-index: inherit;
}

::-webkit-scrollbar {
  display: none;
}

.flip {
  width: 100%;
  opacity: 1;
  transition: width 0.3s, opacity 0.2s ease-in;
}

.flip.active {
  width: 0%;
  opacity: 0;
  transition: width 0.3s, opacity 0.2s ease-out;
}

body {
  min-height: -webkit-fill-available;
  zoom: 100%;
  font-weight: 400;
  font-family: "Quicksand", sans-serif;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

p {
  margin-bottom: 0;
}

.ant-select-selector {
  border-radius: 5px !important;
}

.ant-input-prefix {
  font-weight: 600;
}

.pac-container {
  border-radius: 5px;
  box-shadow: none;
}

.hdpi.pac-logo:after {
  background-image: none;
}

h1,
.ant-page-header-heading-title {
  font-size: 24px;
  padding-right: 12px;
  margin-right: 0;
  font-weight: 400;
}

h2 {
  font-size: 20px !important;
  font-weight: 400;
}

h3,
.ant-form-item-label label {
  font-size: 16px;
  font-weight: 400;
}

h4 {
  font-size: 14px;
}

.message-section::-webkit-scrollbar {
  display: none;
}

.ant-page-header-heading-extra {
  margin: 0;
}

html,
#root {
  /* height: 100%; */
  /* height: -webkit-fill-available; */
  width: -webkit-fill-available;
}

.App {
  text-align: center;
}

.ant-space {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#input {
  min-width: 48px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-page-header-heading {
  align-items: center;
}

.cstm-wrapper {
  display: flex;
  flex-direction: column;
  height: inherit;
  position: relative;
}

.header-details {
  display: flex;
}

.sub-header-text {
  margin: 0;
  padding-left: 8px;
  padding-right: 8px;
}

/* .message-form {
  position: sticky;
  bottom: 0;
} */

.ant-form-item-label > label {
  height: auto;
}

/* 
@media (min-width: 425px) {
  .site-page-header {
    border-image: linear-gradient(130deg, #3a3960 20%, #d56994 70%, #eb9a92 100%);
    border-image-slice: 1;
    border-bottom: 1px solid;
    z-index: 3;
  }
} */

.ant-rate-star-first, .ant-rate-star-second{
  color:rgba(0, 0, 0, 0.25)
}

.menu-item {
  border-radius: 5px;
}
.ant-card-grid {
  padding: 8px;
  border-radius: 5px;
  box-shadow: none;
  text-align: center;
}

.menu-item-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.soco-page {
  max-width: 100vw;
  padding: 32px 16px 16px 16px;
}

/* .soco-page{
} */

.tight.ant-form-item-with-help .ant-form-item-explain {
  min-height: 0;
}
/* .ant-form-item-with-help .ant-form-item-explain {
} */

.ant-input-group .ant-input {
  text-align: left;
}

.message-section {
  padding: 10px 10px 30px;
  /* height: calc(100vh - 150px); */
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.ant-divider-horizontal.ant-divider-with-text {
  font-weight: 400;
}

.portfolioItem {
  height: 500px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

img {
  object-fit: cover;
  object-position: center;
}

.ant-btn-icon-only {
  width: fit-content;
}

.ant-btn-round {
  border-radius: 5px;
}

.ant-btn-round.ant-btn-lg {
  height: fit-content;
  width: fit-content;
  padding: 0 15px;
  background-color: #3a3960;
  color: #f5f5f5;
  font-size: 20px;
  line-height: 50px;
  box-shadow: rgb(245 157 148) 1px 2px;
}

.stretchy > img {
  object-fit: contain;
}

.contactPageStyle {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.testHearder {
  display: flex;
  padding-top: 16px;
}

.ant-page-header-heading-left {
  margin: 0;
}

.avatar {
  flex: 1;
  max-width: fit-content;
  padding: 0 8px 0 16px;
}

.info {
  flex: 1;
}

.ant-picker-range {
  width: 100%;
}

.divider {
  padding: 0 16px 0 16px;
}

.profile {
  padding: 0 16px 0 16px;
}

.profileCard {
  border-radius: 5px;
}

.ant-form-item-control-input {
  min-height: 0;
}

.links {
  width: 300px;
  text-align: center;
  padding-top: 16px;
}

.anticon {
  font-size: 16px;
}

.link {
  font-size: 24px;
}

.code {
  padding: 16px;
}

.detailsTemplate {
  padding: 0 20px 0 20px;
}

.ant-btn-icon-only {
  height: fit-content;
}

@keyframes animate {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -75%) rotate(360deg);
  }
}

/* LAYOUT */
.menu-body {
  padding: 0 16px 0 16px;
  overflow-y: scroll;
  height: 100%;
  z-index: 1;
}

.layout-body {
  padding: 32px 16px 0px 16px;
  overflow-y: scroll;
  height: 100%;
  z-index: 1;
}

.ant-rate-star:not(:last-child) {
  margin-right: 15px;
}
/* LOADER */
.loader {
  z-index: 1000;
  align-items: center;
  /* background-color: rgba(250, 250, 250, 1); */
  backdrop-filter: blur(5px);
  position: fixed;
  display: flex;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  justify-content: center;
}

/* CARD */

.service-card-back {
  /* padding: 2px; */
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: fit-content;
}

.card-back {
  padding: 0px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 16px 0 32px;
  height: fit-content;
}

/* ANTD COLOR DEFINITIONS */
/* .ant-rate, .ant-menu-item-icon {
  color: #eb9a92
}

.ant-rate-star-first, .ant-rate-star-second, .ant-menu-item, .ant-tabs, .ant-select-selector, .ant-checkbox-group, .ant-checkbox-wrapper, .ant-form, .ant-input-group, .ant-input, .ant-btn, .ant-btn-dashed, .ant-page-header-heading-title, .ant-page-header-back-button {
  color: #3a3960;
}

.ant-switch-checked {
  background-color: #3a3960;
} */

.floating-action {
  position: absolute;
  padding: 8px 12px;
  border-radius: 5px;
  top: -16px;
  right: 16px;
}

.floating-status {
  position: absolute;
  padding: 6px 12px;
  border-radius: 5px;
  bottom: -20px;
  right: 16px;
}

/* PICTURE CARD */
.middle-float {
  position: relative;
  padding: 6px 12px;
  border-radius: 5px;
  width: fit-content;
  z-index: 2;
  top: -12px;
  font-weight: 600;
}

.picture-card-title {
  font-size: 32px;
  text-align: start;
  padding: 0 16px 8px 16px;
}

.picture-card-title.gap {
  padding-top: 16px;
}

.picture-card-desc {
  font-size: 16px;
  text-align: start;
  padding: 0 16px 0 16px;
}

.picture-card-inner {
  border-radius: 5px;
  min-width: fit-content;
}

.picture-card-info {
  flex: 1;
  margin-top: -20px;
  min-height: 100%;
  max-height: 100%;
  position: relative;
  padding: 16px 0 32px 0;
}

.picture-card-cover {
  max-width: 100%;
  background: black;
  height: 200px;
  border-radius: 5px 5px 0px 0px;
  min-width: fit-content;
  z-index: 1;
}

/* INFO CARD */

.info-card-title {
  font-size: 32px;
  text-align: start;
  padding: 0 16px 8px 16px;
}

.info-card-inner {
  padding: 16px;
  border-radius: 5px;
}

/* INFO */

.info-text {
  padding-bottom: 8px;
  overflow-wrap: anywhere;
}

/* INDICATOR DOTS */

.indicator-dot {
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 5px;
  background-color: grey;
  margin: 7px 5px;
  transition-duration: 300ms;
}

.indicator-wrapper {
  position: absolute;
  width: 100%;
  z-index: 95;
  bottom: 0px;
  text-align: center;
}

/* PROFILE SETTINGS */

.setting-item {
  width: 100%;
  padding: 8px 0 8px 0;
  border-bottom: 1px solid;
}

.setting-title {
  font-size: 20px;
  text-align: start;
}

.setting-button {
  text-align: end;
}

/* LOGIN PAGE */

.login-page {
  display: flex;
  height: fit-content;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 24px;
}
